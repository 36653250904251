// Section - Mint
#header-mint {
	padding-top: 40px;
	padding-bottom: 40px;
	background: #FE6C58;
	background: #171634;
	border-bottom: 1px solid #35235e;
	// text-align: center;
//	background: url("../../../assets/img/bg-mint.png") no-repeat bottom center;
//	background-size: cover;
	// padding-top: 150px;
	// padding-bottom: 5px;

	.block-body {
		position: relative;
		text-align: center;
		// margin-bottom: 40px;

		.text-connect {
			color: #fff;
			width: 100%;
			position: absolute;
			top: 42px;
			left: 0;
			font-size: 13px;
			font-weight: 300;
		}

		.block-box {
			width: 150px;
			margin: 0 40px 0 0;
	    position: relative;

			.total-mint-price {
				pointer-events: none;
				position: absolute;
				bottom: -18px;
				left: 0;
				width: 100%;
				font-size: 13px;
				color: #fff;
				font-weight: 300;
			}
		}

		button {
			overflow: visible;
			position: relative;
			width: 170px;
			height: 45px;
			line-height: 43px;

			&.button-connect {
				position: relative;
				top: -12px;
			}

			.total-mint-price {
				display: none;
				pointer-events: none;
				position: absolute;
				top: -45px;
				left: 0;
				width: 100%;
				font-size: 13px;
				font-weight: 400;
			}
		}
	}
}

#header-main,
#header-mobile {

	.social-link {
		height: 44px;
		width: 44px;
		min-height: auto;
		padding: 0 !important;
		margin: 0 !important;
		border-radius: 500px;

		&.social-discord,
		&.social-twitter {

			.social-icon {
				height: 22px;
				width: 22px;
			}

			path {
				fill: #fff;
			}
		}

		&.social-discord {
			background: #5865F2;
		}
		&.social-twitter {
			background: #1DA1F2;
		}
		&.social-opensea {
			color: #2081E2 !important;
			padding: 0;

			.social-icon {
				height: 44px;
				width: 44px;
			}
		}
	}
}

// Main header
#header-main {
	height: 100px;

	/* Style wrapper */
	background: transparent;

	/* Style wrapper-core */
	.wrapper-core {
		width: 100%;
		@include default-width-max;
	}

	&.header-float {
		position: absolute;
		width: 100%;
		z-index: 2;
		background: none;
	}

	.menu-logo {

		a,
		span {
			padding: 0;

			img {
				width: 180px;
			}
		}
	}

	.menu-item-top {

		&.menu-parent {

			> a > .menu-name:after {
				position: relative;
				content: "\f107";
				font-family: "Font Awesome 5 Pro";
				padding-left: 6px;
			}
		}

		&.menu-item-link {

			> a {
				position: relative;
				color: #fff;
				font-size: 15px;
				font-weight: 500;
				text-transform: uppercase;
				margin: 0 10px;
				padding: 0 10px;

				.menu-highlight {
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 0;
					height: 3px;
					background: $color_global_1;
					@include default-transition([width], 0.3s, ease);
				}
			}

			> a:hover,
			&.menu-active {
				color: $color_global_1;

				.menu-highlight {
					width: 100%;
				}
			}
		}

		&.menu-item-button > .button {
			margin: 20px 0 20px 30px;

			a {
				padding: 0;
				height: 40px;
				width: 140px;
				line-height: 40px;
			}
		}
	}

	.menu-mega  {
		width: 1000px;
	}

	.menu-mega-features {
		padding: 25px 15px 25px 30px;

		.menu-mega-col1,
		.menu-mega-col2 {
			padding-top: 10px;
		}

		.menu-mega-col1 {
			padding-right: 20px;
			border-right: 1px solid $color_border_global_1;
		}

		.menu-mega-col2 .why-grid {
			margin-right: 15px;
		}

		.container-row {

			&.features-type {
				margin-bottom: 40px;

				.title-primary,
				.title-secondary {
					margin: 0;
					padding: 0 10px;
				}

				.title-primary {
					font-weight: 600;
				}

				.title-secondary {
					font-size: 13px;
				}

				.text-highlight {
					font-style: italic;

					i {
						top: 0px;
						left: -4px;
						width: 50px;
						height: 20px;
						background-size: 50px 20px;
					}
				}
			}

			.block-card {

				.block-title {
					font-size: 15px;
					margin-bottom: 5px;
				}

				.block-text {
					font-size: 13px;
				}
			}
		}
	}

	.menu-mega-solutions {
		padding: 25px 15px 25px 30px;

		.menu-mega-col1,
		.menu-mega-col2 {
			padding-top: 10px;
		}

		.menu-mega-col1 {
			padding-right: 30px;
			border-right: 1px solid $color_border_global_1;
		}

		.menu-mega-col2 .solutions-grid {
			margin-right: 15px;
		}

		.container-row {

			&.solutions-type {
				margin-bottom: 40px;

				.title-primary,
				.title-secondary {
					margin: 0;
					padding: 0 10px;
				}

				.title-primary {
					font-weight: 600;
				}

				.title-secondary {
					font-size: 13px;
				}
			}

			.block-card {

				.block-title {
					font-size: 15px;
					margin-bottom: 5px;
				}

				.block-text {
					font-size: 13px;
				}
			}
		}
	}
}

// Main mobile
#header-mobile {
	position: absolute;
	width: 100%;

	.menu-logo {

		a {
			padding: 0;

			img {
				width: 130px;
			}
		}
	}

	.uk-sticky-fixed {
		background: #0c1e3c;
	}

	#header-mobile-sticky {
		background: none;
		border-bottom: none;
		padding: 0 20px 0 20px;

		.uk-logo {
			padding: 0;
		}

		#header-mobile-buttons {
				display: none;

			// Hide on pricing page
//			@at-root .page-pricing #{&} {
//				display: none;
//			}
		}

		#header-mobile-links {
			padding: 13px 0;
		}

		.social-link {
			height: 34px;
			width: 34px;

			&.social-discord,
			&.social-twitter {

				.social-icon {
					height: 16px;
					width: 16px;
				}
			}

			&.social-discord {
			}
			&.social-twitter {
			}
			&.social-opensea {

				.social-icon {
					height: 44px;
					width: 44px;
				}
			}
		}
	}

	#header-mobile-toggle {

		.header-toggle {
			flex-direction: column;
			justify-content: space-between;
			width: 50px;
			height: 34px;
			min-height: auto;
			padding: 10px;

			.header-toggle-bar {
				display: block;
				height: 2px;
				width: 20px;
				background: #999;
				@include default-transition([all], 0.3s, ease);
			}

			&:hover .header-toggle-bar {
				background: #bababa;
			}
		}
	}

	// Controls panel width (keep out of main #header-mobile-menu nest)
	#header-mobile-menu {

		.uk-offcanvas-bar {
 			width: 250px;
			left: -250px;
		}

		&.uk-open > .uk-offcanvas-bar {
			left: 0;
		}
	}

	#header-mobile-menu {

		&.uk-offcanvas-overlay:before {
			background: rgba(0, 0, 0, 0.25);
		}

		.uk-offcanvas-bar {

			.uk-close {
				color: #999;
				@include default-transition([all], 0.3s, ease);

				&:hover {
					color: #bababa;
				}
			}
		}

		#header-mobile-menu-core {
			color: $color_text_1;
			background: #0c1e3c;
			padding-top: 25px;
			padding-left: 30px;
			padding-right: 30px;
			box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);

//.uk-offcanvas-flip .uk-offcanvas-bar


			.block-card {

				.block-card-core {
					column-gap: 20px;

					.block-header {
						width: 55px;

						.block-icon {
							width: 30px;
							height: 30px;
						}
					}

					.block-body {

						.block-title {
							font-size: 14px;
							color: $color_heading_1;
							margin-bottom: 5px;
						}

						.block-text {
							max-width: 190px;
							font-size: 13px;
							color: $color_text_1;

							p {
								margin-bottom: 12px;
							}
						}
					}
				}
			}

			.menu-item {
				font-size: 14px;

				&.menu-item-link {
					border-top: 1px solid rgba(255, 255, 255, 0.1);

					&:last-child {
						border-bottom: 1px solid rgba(255, 255, 255, 0.1);
					}

					a {
						font-size: 14px;
						color: #fff !important;
						padding: 15px 5px;

						&:hover {
							color: $color_global_1 !important;
						}
					}

					&.menu-item-pricing,
					&.menu-item-demo {

						a {
							color: #2a2a2a;
							font-family: 'Roboto';
							font-weight: 500;
							padding: 14px 0;
							border-top: 1px solid #eee;
							border-bottom: 1px solid #eee;
						}


						&.menu-item-pricing {
							margin-top: 10px;
						}

						&.menu-item-demo {
							margin-bottom: 25px;

							a {
								border-top: none;
							}
						}
					}
				}

				&.menu-item-button {

					button {
						width: 150px;
						margin: 0 0 15px 0;

						a {
							display: block;
						}
					}
				}

				&.menu-item-features {

					.menu-mega-col1 {
						margin-bottom: 20px;
					}
				}
			}
		}
	}

	#header-mobile-logo {

		img {
	    width: 120px;
		}
	}
}



// iPads, Tablets
@media only screen and (max-width: 480px) {

	#header-mobile-menu {

		.block-card {

			&.block-style2 {

				.block-card-core {
					text-align: left;
					align-items: start;
					align-content: start;
					flex-direction: row;
				}
			}
		}
	}
}

@media only screen and (max-width: 320px) {

	// Controls panel width (keep out of main #header-mobile-menu nest)
	#header-mobile {

		#header-mobile-menu {

			.uk-offcanvas-bar {
//				width: 300px;
//				left: -300px;
				padding-left: 20px;
				padding-right: 20px;
			}

			&.uk-open > .uk-offcanvas-bar {
				left: 0;
			}
		}
	}
}