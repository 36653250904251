body.page-error.error-404 {

	.container.block-error {
		text-align: center;
		padding: 80px 30px;
		border-top: 1px solid #eee;
		border-bottom: 1px solid #eee;

		.block-body {

			.block-media {
				margin-bottom: 40px;
			}

			.block-text {
				max-width: 550px;
				margin: 0 auto 30px;

				p {
					margin: 0;
				}
			}

			.button {
				margin: 0;
			}
		}
	}
}