.block-card {

	position: relative;
	@include default-transition([all], 0.3s, ease);

	.block-header,
	.block-body,
	.block-footer {
		@include default-transition([all], 0.3s, ease);
	}

	.block-link {
		color: inherit;
	}

	// Block style 1
	&.block-style1 {
		text-align: center;
//		background: #0d121e;
		padding: 35px 20px 12px;
		border-radius: 8px;

		&.block-card-link {
			cursor: pointer;
			padding: 0;

			> .block-link {
				display: block;
				padding: 35px 20px 12px;
			}
		}

		&.block-card-bg {
			background: rgba(255, 255, 255, 0.025);
			padding-top: 40px !important;
			padding-bottom: 13px !important;


			.block-icon.block-style1 {
			    width: 85px;
			    height: 85px;

				svg {
					width: 20px;
				}
			}
		}

		.block-header {
				margin-bottom: 18px;

			.block-icon {
				margin: 0 auto;
			}
		}

		.block-body {
			max-width: 310px;
			margin: 0 auto;

			.block-title {
				color: #fff;
				font-size: 32px;
				margin-bottom: 10px;
			}
		}

		.block-footer {
			position: absolute;
			width: 100%;
			left: 0;
			bottom: 0;
			@include default-hide;

			.button {
				margin: 0;
			}
		}

		&:hover {

			.block-footer {
				bottom: -20px;
				@include default-show;
			}
		}

		// Styling for block-color-icon
		&.block-color-icon:hover {

			.block-header {

				.block-icon {
					fill: rgba(255, 255, 255, 0.75);
					@include default-color(#{&}, [background]);
				}
			}
		}

		// Styling for block-color-button
		&.block-color-button {

			.block-footer {
				.button {
					color: #fff;
					@include default-color(#{&}, [background border-color]);
					box-shadow: none;
				}
			}
		}
	}

	// Block style 2
	&.block-style2 {
		text-align: center;
//		background: #0d121e;
		padding: 35px 20px 12px;
		border-radius: 8px;

		&.block-card-link {
			cursor: pointer;
			padding: 0;

			> .block-link {
				display: block;
				padding: 35px 20px 12px;
			}
		}

		.block-header {

			.block-image {
				margin-bottom: 18px;

				img {
					margin: 0 auto;
					border-radius: 15px;
				}
			}
		}

		.block-body {
			max-width: 310px;
			margin: 0 auto;

			.title-info {
				font-size: 24px;
			}
			.block-title {
				color: #fff;
				font-size: 32px;
				margin-bottom: 10px;
			}
		}

		.block-footer {
			position: absolute;
			width: 100%;
			left: 0;
			bottom: 0;
			@include default-hide;

			.button {
				margin: 0;
			}
		}

		&:hover {

			.block-footer {
				bottom: -20px;
				@include default-show;
			}
		}
	}
}
