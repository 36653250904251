.block-list {

	&.block-style1 {
		display: flex;
		align-items: flex-start;
		margin-bottom: 7px;

		&:last-child {
			margin-bottom: 0;
		}

		.block-header {

			.block-icon {
				margin: 0 20px 10px 0;
			}
		}

		.block-body {

			.block-title {
				font-size: 18px;
				font-weight: 600;
				margin-bottom: 5px;
			}
		}
	}

	&.block-style2 {
		@include default-color(#{&}, [background], ' .list-item:before');

		.list-item {
			font-size: 14px;
			line-height: 25px;
			margin-bottom: 25px;

			&:last-child {
				margin-bottom: 0;
			}

			&:before {
				display: inline-block;
				font-family: 'Font Awesome 5 Pro';
				content: "\f00c";
				color: #777;
				background: #f1f1f1;
				font-weight: 400;
				text-align: center;
				width: 25px;
				height: 25px;
				line-height: 25px;
				margin-right: 15px;
				border-radius: 500px;
			}
		}

		&[class*="x"] .list-item:before {
			color: #fff;
		}
	}
}