.page-industry {

	.container.industry-header {
		border-bottom: 1px solid $color_border_global_1;

		.industry-header-col1 {

			.block-header .title-info {
				margin-bottom: 10px;
			}

			.block-body .block-text {
				max-width: 500px;
				margin-bottom: 42px;
			}
		}

		.industry-header-col2 {

			.block-image.header-solution {
				padding: 130px 0;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}

	.container.industry-solution {
		padding-bottom: 100px;

		.block-image {
//			min-height: 400px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.block-body {

			.block-text {
				margin-bottom: 33px;
			}
		}
	}

	.container.industry-solution1 {
		padding-top: 80px;
	}

	.container.industry-solution2 {
	}

	.container.industry-solution3 {
	}

	.container.industry-solution4 {
	}

	.container.industry-solution5 {
	}

	.container.industry-solution6 {
	}
}