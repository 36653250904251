.page-wp {

	// Alternative design with no page intro
	&.page-wp-v2 {

		.container.wp-header {
			display: none;
		}

		.container.wp-plans {
			padding-top: 100px;
		}
	}

	.container.wp-header {
		text-align: center;
		padding-top: 50px;
		padding-bottom: 60px;

		.block-text {
			max-width: 540px;
			margin: 0 auto;
		}
	}

	.container.wp-contact {
		padding-bottom: 30px;

		.wp-contact-col1 {
			padding-top: 150px;
			padding-bottom: 150px;

			.block-header {
				margin-bottom: 40px;
			}

			.block-body {

				.block-list {
					margin-bottom: 22px;

					&:last-child {
						margin-bottom: 0 !important;
					}

					.block-title {
						font-weight: 500 !important;
					}
				}
			}

			.block-overlay {

				.block-overlay-abs {
					right: -150px;
					border-radius: 0 10px 10px 0;
				}
			}
		}

		.wp-contact-col2 {
			text-align: center;
		}
	}

	.container.wp-types {
		padding-bottom: 100px;

		.wp-types-extra {
			width: 100%;
			padding-top: 50px;

			.title-primary {
				font-size: 25px;
				font-weight: 500;
				margin-bottom: 40px;
			}

			.list-item {
				width: 250px;
				margin-bottom: 30px !important;
			}
		}
	}

	.container.wp-faq {
		background: #f7f7f7;
		padding-top: 90px;
		padding-bottom: 80px;

		.container-core {
			max-width: 900px;
		}
	}
}

// Small screens, laptops
@media only screen and (max-width: 800px) {

	.page-wp {

		.container.wp-header {
			padding-top: 30px;
			padding-bottom: 10px;
		}

		.container.wp-types {
			padding-bottom: 50px;

			.wp-types-extra {
				max-width: 600px;

				.block-header {
					text-align: center;
				}
			}
		}
	}
}

// iPads, Tablets
@media only screen and (max-width: 480px) {

	.page-wp {

		.container.wp-types {

			.wp-grid {

				.block-body {
					display: flex;
					flex-direction: column;
					align-items: center;
				}
			}
		}
	}
}

// Small screens, laptops
@media only screen and (max-width: 800px) {

	.page-wp {

		#main {
			border-bottom: 1px solid #eee;
		}

		.container.wp-contact {

			.wp-contact-col1 {
				padding-top: 60px;


				.block-header {
					margin-bottom: 30px;

					.title-subsection {
						font-size: 30px;
					}
				}

				.block-body {

					.block-list {
						margin-bottom: 10px;
					}
				}
			}

			.wp-contact-col2 {
				margin-top: -100px;
			}
		}
	}
}

// Mobile devices
@media only screen and (max-width: 360px) {


	.page-wp {

		.container.wp-contact {

			.wp-contact-col1 {

				.block-header {

					.title-subsection {
						font-size: 22px;
					}
				}
			}
		}
	}
}