.block-form {

	&[data-sent="1"] {

		.block-form-content {
			display: none;
		}

		.block-form-message {
			display: block;
		}
	}

	// Main contact form
	.block-form-content {
		margin: 15px 0;
		padding: 40px 40px;
		background: #fff;
		border-radius: 8px;
		box-shadow: 0 0 15px 0 rgb(0, 0, 0, 0.1);

		.block-form-row {
			margin-bottom: 22px;

			&.row-submit {
				margin: 30px 0 10px;

				button {
					height: 50px;
					line-height: 50px;
					padding-top: 0;
					padding-bottom: 0;
					border: none;
				}
			}
		}

		.block-form-field {
			position: relative;

			label {
				display: block;
				font-size: 14px;
				padding-bottom: 7px;
			}

			input,
			textarea {
				outline-color: #ddd;
				width: 100%;
				display: block;
				border: 1px solid #eee;
				padding: 10px 20px;
				border-radius: 4px;
				box-sizing: border-box;
			}

			textarea {
				resize: none;
			}

			.tooltip.error {
				display: none;
				position: absolute;
				top: 0;
				right: 0;
				color: $color_ff6f91;
				font-size: 12px;
				text-transform: uppercase;
				background: $color_ff6f91_opacity;
				padding: 1px 8px;
			}

			&.form-error {

				.form-option {
					outline: none;
					border-color: $color_ff6f91;
				}

				.tooltip.error {
					display: block;
				}
			}
		}
	}

	// Contact form confirmation
	.block-form-message {
		display: none;
		padding: 40px 40px;
		background: #fff;
		border-radius: 8px;
		box-shadow: 0 0 15px 0 rgb(0, 0, 0, 0.1);


		.block-header {

			.block-icon-custom {
				color: #fff;
				text-align: center;
				font-size: 50px;
				width: 100px;
				height: 100px;
				line-height: 100px;
				margin: 10px auto 0;
				background: #00C9B7;
				border-radius: 500px;

				i {
					display: block;
					height: 100px;
					line-height: 100px;
				}

			}
		}

		.block-body {

			.block-text {
				text-align: center;
				margin-top: 20px;

				p {
					margin: 0;
				}
			}
		}
	}
}


// iPads, Tablets
@media only screen and (max-width: 480px) {

	.block-form {

		.block-form-content,
		.block-form-message {
			padding: 25px 30px;
		}
	}
}