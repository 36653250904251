/* Import custom fonts */
@font-face {
  font-family: spaceboy;
  src: url("../../lib/fonts/spaceboy/spaceboy.ttf");
}

/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;600;700;900&display=block');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;900&display=block');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;900&display=block');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;900&display=block');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;500;600;700;900&display=block');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=block');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=block');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;300;400;500;700;900&display=block');

@import url('https://fonts.googleapis.com/css2?family=Nanum+Pen+Script:wght@100;300;400;500;700;900&display=block');

/* Import helper file */
@import './helper/variables';
@import './helper/mixins';

/* Import 3rd party files */
//@import 'normalize.css';
//@import '~uikit';
//@import '~@fortawesome/fontawesome-pro/css/all.min.css';

/* Override UIkit component styles */
@import './component-uikit/tab';

/* Import component styles */
@import './component/button';
@import './component/text';

/* Import utility styles */
@import './utility/border-radius';
@import './utility/flex';
@import './utility/grid';

/* Import block styles (blocks are our custom components) */
@import './block/accordion';
@import './block/card';
@import './block/cta';
@import './block/countdown';
@import './block/divider';
@import './block/form';
@import './block/icon';
@import './block/image';
@import './block/list';
@import './block/modal';
@import './block/tooltip';
@import './block/overlay';
@import './block/packages';

/* Import layout styles */
@import './layout/global';
@import './layout/container';
@import './layout/header';
@import './layout/main';
@import './layout/footer';

/* Import page specific files styles - Top level pages */
@import './page/home';
@import './page/pricing';
@import './page/demo';

/* Import page specific files styles - Feature pages */
@import './page/feature/segment';
@import './page/feature/recording';
@import './page/feature/heatmap';
@import './page/feature/funnel';
@import './page/feature/survey';
@import './page/feature/feedback';

/* Import page specific files styles - Solution pages */
@import './page/solution/role';
@import './page/solution/industry';
@import './page/legal/legal';

/* Import page specific files styles - WordPress pages */
@import './page/wp';

/* Import error page page styling */
@import './page/error/error';

/* Import styles for 3rd party services */
@import './service/service';

/* Import responsive styles */
@import './app-responsive';