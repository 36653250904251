body.page-legal {

	#main {
		padding-bottom: 80px;
		border-top: 1px solid #eee;
		border-bottom: 1px solid #eee;

		strong {
			font-weight: 600;
		}

		h1.block-title {
			font-size: 36px;
			font-weight: 600;
			margin-bottom: 20px;
		}

		h2.block-title {
			font-size: 24px;
			font-weight: 500;
			margin-bottom: 20px;
		}

		h3.block-title {
			font-size: 21px;
			font-weight: 500;
			margin-bottom: 15px;
		}

		h4.block-title {
			font-size: 18px;
			font-weight: 500;
			margin-bottom: 15px;
		}

		.uk-divider {
			margin-top: 35px;
			margin-bottom: 35px;
		}

		.container {

			.container-col {

				&.page-col1 {
					width: 300px;
					padding-top: 50px;

					.uk-nav {
						z-index: 0;
						padding-top: 40px;
					}

					.uk-list {
						font-size: 13px;

						li {
							padding-left: 20px;

							&.uk-active {

								a {
									color: $color_global_1;
								}
							}

							a {
								padding: 0;
								color: $color_text_1;

								&:hover {
									color: $color_global_1;
								}
							}
						}
					}
				}

				&.page-col2 {
					padding-top: 80px;
				}
			}

		}
	}
}

// iPads, Tablets
@media only screen and (max-width: 480px) {

	body.page-legal {

		#main {

			// Break words on links to ensure correct mobile display
			a {
				word-break: break-word;
			}
		}
	}
}

