body.page-error.error-503 {

	.container.block-error {

		.error-col1 {

			.block-text {
				margin-bottom: 30px;
			}

			.block-button {

				button {
					line-height: 45px;
					height: 45px;
					width: 150px;
					padding: 0;
				}
			}
		}

		.error-col2 {
			text-align: center;
		}
	}
}