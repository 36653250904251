.page-funnel {

	.container.funnel-header {
		padding-bottom: 80px;
	}

	.container.funnel-intro {
	}

	.container.funnel-feature1 {
		padding-bottom: 100px;

		.block-body {

			.block-text {
				margin-bottom: 33px;
			}
		}
	}

	.container.funnel-feature2 {
		padding-bottom: 100px;

		.block-body {

			.block-text {
				margin-bottom: 33px;
			}
		}
	}

	.container.funnel-feature3 {
		padding-bottom: 100px;

		.block-body {

			.block-text {
				margin-bottom: 33px;
			}
		}
	}

	.container.funnel-feature4 {
		padding-bottom: 100px;

		.block-body {

			.block-text {
				margin-bottom: 33px;
			}
		}
	}
}