.block-countdown {

	&.block-style1 {
		background: rgba( 0, 0, 0, 0.5 );
		padding: 30px 40px 23px;
		border-radius: 20px;

		// Adjust styling when minting is live
		&.mint-live {

			.block-header {

				.block-text {
					margin: 0;

					p {
						margin: 0;
					}
				}
			}

			.block-body {
				display: none;
			}
		}

		.block-header {

			.block-title {
				line-height: 1;
				font-size: 30px;
				color: #ffda00;
		    margin-bottom: 4px;

				font-family: 'Poppins';
				font-size: 16px;
				font-weight: 500;
				text-transform: uppercase;
			}

			.block-text {
				color: #fff;
				font-size: 13px;
//				font-weight: 300;
		    margin-bottom: 27px;
			}
		}

		.block-body {
			margin-bottom: 0 !important;

			.block-counter-item {
				width: 50px;

				.item-number {
					font-family: 'roboto';
					font-size: 26px;
					font-weight: 600;
					color: #fff;
				}

				.item-countdown {
					font-family: 'Nanum Pen Script';
					font-size: 20px;
					color: #ffda00;
					margin: 0 !important;
				}
			}
		}
	}
}

// Small screens, laptops
@media only screen and (max-width: 480px) {

	.block-countdown {

		&.block-style1 {
			padding: 25px 30px 18px;
		}
	}
}
