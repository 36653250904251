.block-cta {

	.block-cta-core {
		@include default-width-max;
	}

	.block-footer {

		.button:last-child {
			margin-right: 0;
		}
	}

	&.block-style1 {
		text-align: center;
		padding-top: 100px;
		padding-bottom: 80px;
		background-color: #f7f7f7;
//		background-image: url(../../img/cta-bg-f7f7f7.png);
		background-size: cover;
		background-repeat: repeat-y;
		background-position: center;
		background-attachment: scroll;

		.block-header {

			.title-info {
				margin-bottom: 5px;
			}

			.title-main {
				font-size: 64px;
				font-weight: 600;
				margin-bottom: 45px;
			}
		}

		&.x073b4c {
			text-align: center;
			background-color: #073b4c;
//			background-image: url(../../img/cta-bg-073b4c.png);

			.block-title,
			.block-text {
				color: #fff;
			}
		}
	}
}