.page-pricing {

	// Alternative design with no page intro
	&.page-pricing-v2 {

		.container.pricing-header {
			display: none;
		}

		.container.pricing-plans {
			padding-top: 100px;
		}
	}

	.container.pricing-header {
		text-align: center;
		padding-top: 50px;
		padding-bottom: 40px;

		.block-text {
			max-width: 550px;
			margin: 0 auto;
		}
	}

	.container.pricing-plans {
		padding-bottom: 30px;
	}

	.container.pricing-types {
		padding-bottom: 100px;

		.pricing-types-extra {
			width: 100%;
			padding-top: 50px;

			.title-primary {
				font-size: 25px;
				font-weight: 500;
				margin-bottom: 40px;
			}

			.list-item {
				width: 250px;
				margin-bottom: 30px !important;
			}
		}
	}

	.container.pricing-faq {
		background: #f7f7f7;
		padding-top: 90px;
		padding-bottom: 80px;

		.container-core {
			max-width: 900px;
		}
	}
}

// Small screens, laptops
@media only screen and (max-width: 800px) {

	.page-pricing {

		.container.pricing-header {
			padding-top: 30px;
			padding-bottom: 10px;
		}

		.container.pricing-types {
			padding-bottom: 50px;

			.pricing-types-extra {
				max-width: 600px;

				.block-header {
					text-align: center;
				}
			}
		}
	}
}

// iPads, Tablets
@media only screen and (max-width: 480px) {

	.page-pricing {

		.container.pricing-types {

			.pricing-grid {

				.block-body {
					display: flex;
					flex-direction: column;
					align-items: center;
				}
			}
		}
	}
}


