.page-heatmap {

	.container.heatmap-header {
		padding-bottom: 80px;
	}

	.container.heatmap-intro {
	}

	.container.heatmap-feature1 {
		padding-bottom: 100px;

		.block-body {

			.block-text {
				margin-bottom: 33px;
			}
		}
	}

	.container.heatmap-feature2 {
		padding-bottom: 100px;

		.block-body {

			.block-text {
				margin-bottom: 33px;
			}
		}
	}

	.container.heatmap-feature3 {
		padding-bottom: 100px;

		.block-body {

			.block-text {
				margin-bottom: 33px;
			}
		}
	}

	.container.heatmap-feature4 {
		padding-bottom: 100px;

		.block-body {

			.block-text {
				margin-bottom: 33px;
			}
		}
	}
}