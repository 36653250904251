.block-overlay {

	.block-overlay-abs {
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: auto;
		width: 100%;
		height: 100%;
		background: $color_bg_grey;

		@include default-color(#{&}, [background]);

		&.block-overlay-wide {
			width: 500%;
		}

		&.block-overlay-left {
			left: 0;
			right: auto;
		}

		&.block-overlay-right {
			left: auto;
			right: 0;
		}

		&.block-overlay-left-clear,
		&.block-overlay-right-clear {
			z-index: 0;
			background: #fff;
		}
		&.block-overlay-left-clear {
			left: -500%;
			right: auto;
		}
		&.block-overlay-right-clear {
			right: -500%;
			left: auto;
		}
	}
}