/* CSS Flex */
.block-flex {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	&.block-flex-column {
		flex-direction: column;
	}

	&.block-flex-start {
		align-items: start;
		justify-content: flex-start;
	}

	&.block-flex-around {
		justify-content: space-around;
	}

	&.block-flex-center {
		justify-content: center;
	}

	// Column widths - Flex basis
	.flex-col-05 {
		flex: 0.5; // flex: flex-grow flex-shrink flex-basis
	}
	.flex-col-1 {
		flex: 1; // flex: flex-grow flex-shrink flex-basis
	}
	.flex-col-2 {
		flex: 0 1 50%; // flex: flex-grow flex-shrink flex-basis
	}
	.flex-col-3 {
		flex: 3; // flex: flex-grow flex-shrink flex-basis
	}
	.flex-col-4 {
		flex: 4; // flex: flex-grow flex-shrink flex-basis
	}
	.flex-col-5 {
		flex: 5; // flex: flex-grow flex-shrink flex-basis
	}
	.flex-col-2-1-1 {
		flex: 2; // flex: flex-grow flex-shrink flex-basis
	}
	.flex-col-2-1-2 {
		flex: 1; // flex: flex-grow flex-shrink flex-basis
	}
	.flex-col-5-1-2 {
		flex: 2; // flex: flex-grow flex-shrink flex-basis
	}
	.flex-col-5-1-4 {
		flex: 1.5; // flex: flex-grow flex-shrink flex-basis
	}

	// Column widths - Traditional Width
	.flex-col-1p {
		width: 100%; // flex: flex-grow flex-shrink flex-basis
	}
	.flex-col-2p {
		width: 50%; // flex: flex-grow flex-shrink flex-basis
	}

	// Disable grow / shrink
	.flex-fix {
		flex-grow: 0;
		flex-shrink: 0;
	}
	.flex-fix-grow {
		flex-grow: 0;
	}
	.flex-fix-shrink {
		flex-shrink: 0;
	}

	// Add gap sizes
	@for $count from 1 through 20 {
		$size : $step_loop * $count;
		&.flex-gap-#{$size} {
    	    gap: #{$size}px;
        }
	}

	// Add row gap sizes
	@for $count from 1 through 20 {
		$size : $step_loop * $count;
		&.flex-row-gap-#{$size} {
    	    row-gap: #{$size}px;
        }
	}

	// Add column gap sizes
	@for $count from 1 through 20 {
		$size : $step_loop * $count;
		&.flex-column-gap-#{$size} {
    	    column-gap: #{$size}px;
        }
	}
}

// Small screens, laptops
@media only screen and (max-width: 800px) {

	.block-flex {

		&.w800-block-flex-column {
			flex-direction: column;
		}
		&.w800-block-flex-column-reverse {
			flex-direction: column-reverse;
		}
		&.w800-block-flex-center {
			align-items: center;
		}

		.w800-flex-col-1p {
			width: 100%;
		}
	}
}

// iPads, Tablets
@media only screen and (max-width: 480px) {

	.w480-block-flex-column {
		flex-direction: column;
	}
	.w480-block-flex-column-reverse {
		flex-direction: column-reverse;
	}
}
