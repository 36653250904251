.button {
	overflow: hidden;
	cursor: pointer;
	background: none;
	border: 1px solid $color_border_button_1;
	margin: 0 20px 20px 0;
	@include default-transition([all], 0.3s, ease);
//	@include default-transition([background opacity], 0.3s, ease-in-out);

	&[disabled] {
		cursor: wait;
	}

	&.button-link {
		padding: 0 !important;

		a {
			display: inline-block;
			color: inherit;
			@include default-transition([all], 0s, ease);
		}
	}

	// Remove margins
	&.button-nomargin {
		margin: 0
	}

	// Wide button
	&.button-wide {
		width: 100%;
		box-sizing: border-box;

		a {
			width: 100%;
			box-sizing: border-box;
		}
	}

	// Button has an active type (e.g. loading)
	&.button-active {
		position: relative;

		.active-on {
			display: none;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			font-size: 25px;
		}

		&.active-loading {
			pointer-events: none;

			.active-on {
				display: block;
			}
			.active-off {
				display: none;
			}
		}
	}

	// Button sizes
	&.button-s,
	&.button-s a {
		font-size: 14px;
		padding: 4px 15px;
	}
	&.button-m,
	&.button-m a {
		font-size: 14px;
		padding: 6px 20px;
	}
	&.button-l,
	&.button-l a {
		padding: 10px 24px;
	}

	// Button - Style 1
	&.button-v1 {
		color: #fff;
		background: $color_global_1;
		border-color: $color_global_1;
		@include default-color(#{&}, [background border-color]);
		@include default-color(#{&}, [background border-color], ':hover', 'dark');
	}
	&.button-v1:hover,
	.button-sim:hover &.button-v1 {
		color: #fff;
		background: darken( $color_global_1, 5% );
		border-color: darken( $color_global_1, 5% );
	}
	@at-root .button-sim:hover .button-v1 {
		@include default-color(#{&}, [background border-color], '', 'dark');
	}

	// Button - Style 2
	&.button-v2 {
		color: $color_global_1;
		border-color: $color_global_1;
	}
	&.button-v2:hover,
	.button-sim:hover &.button-v2 {
		color: #fff;
		background: $color_global_1;
		border-color: $color_global_1;
	}

	// Button - Style 3
	&.button-v3 {
		color: $color_text_2;
		background: #fff;
		border-color: $color_border_button_1;
	}
	&.button-v3:hover,
	.button-sim:hover &.button-v3 {
		color: #fff;
		background: $color_global_1;
		border-color: $color_global_1;
	}

	// Button - Style 4
	&.button-v4 {
		color: $color_text_2;
		background: #fff;
		border-color: $color_border_button_1;
	}
	&.button-v4:hover,
	.button-sim:hover &.button-v4 {
		color: $color_text_2;
		background: #fff;
		border-color: $color_border_button_2;
		box-shadow: 0 0 10px 0 rgba( 0, 0, 0, 0.15 );
	}
}