/* Override UI Kit - Tab */
.uk-scope {

	.uk-tab {

		&:before {
			display: none;
		}

		&.style1 {
			margin-left: 0;
			margin-right: 0;

			> li {
				width: 100%;
				padding: 0;

				> a {
					font-size: 16px;
					text-transform: none;
					padding: 8px 10px;
					border-bottom: 3px solid #ddd;
					@include default-transition([all], 0.3s, ease);
				}

				> a:hover,
				&.uk-active > a {
					color: #2a2a2a;
					border-color: #073b4c;
				}

				i {
					padding-right: 10px;
				}
			}

			// Styling for custom color tabs
			&[class*="x"] > li > a:hover,
			&[class*="x"] > li.uk-active > a {
				color: inherit;
			}
			@include default-color(#{&}, [border-color], ' > li > a:hover');
			@include default-color(#{&}, [border-color], ' > li.uk-active > a');
		}
	}
}