// Add range of border radius sizes
@for $count from 1 through 20 {
	$size : $step_loop * $count;
	.border-radius-#{$size} {
		border-radius: #{$size}px;
	}
}

// Fully rounded borders
.border-radius-500 {
	border-radius: 500px;
}