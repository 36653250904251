// Main footer
#footer-main {
	height: 100px;

	/* Style wrapper */
	background: transparent;

	/* Style wrapper-core */
	.wrapper-core {
		width: 100%;
		@include default-width-max;
	}

	.menu-logo {

		a {
			padding: 0;

			img {
				width: 180px;
			}
		}
	}

	#footer-main-social {

		.social-link {
			height: 44px;
			width: 44px;
			min-height: auto;
			padding: 8px;
			margin: 0;
			border-radius: 500px;

			&.social-discord,
			&.social-twitter {

				.social-icon {
					height: 22px;
					width: 22px;
				}

				path {
					fill: #fff;
				}
			}

			&.social-discord {
				background: #5865F2;
			}
			&.social-twitter {
				background: #1DA1F2;
			}
			&.social-opensea {
				color: #2081E2;
				padding: 0;

				.social-icon {
					height: 44px;
					width: 44px;
				}
			}
		}
	}
}

// Copyright footer
#footer-copyright {
	background: darken( $color_bg_1, 3% );

	.block-body {
		font-size: 13px;
		color: #7a7a7a;
		text-align: center;
		padding: 13px 20px;
	}
}
