.block-divider {
	position: relative;
	height: 40px;

	&.block-style1 {

		.block-divider-core {
			position: absolute;
			top: -25px;
			width: 100%;
			max-width: 100%;
		}

		.divider-block1,
		.divider-block2 {
			content: "&quot;&quot;";
			display: block;
			position: absolute;
			overflow: hidden;
			border-left: 100vw solid transparent;
			border-right: 0vw solid transparent;
			width: 0;
			height: 0;
			left: 0;
			right: 0;
			margin: auto;
		}

		.divider-block1 {
			top: 0px;
			border-bottom: 40px solid #f2f2f2;
			box-shadow: 0px 10px 0px 0px #f2f2f2;
		}

		.divider-block2 {
			top: 3px;
			border-bottom: 40px solid #fff;
			box-shadow: 0px 10px 0px 0px #fff;
		}
	}

	&.block-style2 {

		.block-divider-core {
			position: absolute;
			top: -20px;
			width: 100%;
			max-width: 100%;
		}

		.divider-block1,
		.divider-block2 {
			content: "&quot;&quot;";
			display: block;
			position: absolute;
			overflow: hidden;
			top: 20px;
			left: 0;
			right: 0;
			width: 0;
			height: 0;
			margin: auto;
			border-top: 40px solid #fff;
			border-bottom: 40px solid #f7f7f7;
		}

		.divider-block1 {
			@include default-color(#{&}, [border-top-color]);
			border-bottom: none;
			border-right: 100vw solid transparent;
			border-left: 0vw solid transparent;
		}

		.divider-block2 {
			@include default-color(#{&}, [border-bottom-color]);
			border-top: none;
			border-left: 100vw solid transparent;
			border-right: 0vw solid transparent;
		}
	}
}