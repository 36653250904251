@mixin default-width-max {
	max-width: $width-max;
	padding: 0 30px;
	margin: 0 auto;
}

@mixin default-width-wide {
	max-width: 100%;
	padding: 0;
}

@mixin default-hide {
	opacity: 0;
	visibility: hidden;
}

@mixin default-show {
	opacity: 1;
	visibility: visible;
}

@mixin default-transition($properties, $duration, $timing) {

	// Set default output value
	$output: ();

	// Loop through each input paramater
	@each $property in $properties {
	    $output: append($output, $property $duration $timing, $separator: comma)
	}

	// Return final output
	transition: $output;
}

@mixin default-color($selector, $properties, $pseudo: '', $base: '') {

	// Assign default variable values
	$base_colors: ();

	// Select color palette to use
	@if $base == opacity {
		$base_colors: $colors_opacity;
	} @else if $base == light {
		$base_colors: $colors_light;
	} @else if $base == dark {
		$base_colors: $colors_dark;
	} @else {
		$base_colors: $colors;
	}

	// Loop through each input paramater
	@each $hex, $rgba in $base_colors {
		@at-root #{$selector}.x#{$hex}#{$pseudo} {
			@each $property in $properties {
				#{$property}: #{$rgba};
			}
		}
	}
}

@mixin default-color-svg($selector, $properties, $pseudo: '', $base: '') {

	// Assign default variable values
	$base_colors: ();

	// Select color palette to use
	@if $base == opacity {
		$base_colors: $colors_opacity;
	} @else if $base == light {
		$base_colors: $colors_light;
	} @else if $base == dark {
		$base_colors: $colors_dark;
	} @else {
		$base_colors: $colors;
	}

	// Loop through each input paramater
	@each $hex, $rgba in $base_colors {
		@at-root #{$selector}.x#{$hex}#{$pseudo} {
			@each $property in $properties {
				#{$property}: #{$rgba};
			}
		}
		@at-root #{$selector}.x#{$hex}#{$pseudo} path {
			@each $property in $properties {
				#{$property}: #{$rgba};
			}
		}
	}
}

@mixin default-color-boxshadow($selector, $shadow, $pseudo: '', $base: '') {

	// Assign default variable values
	$base_colors: ();

	// Select color palette to use
	@if $base == opacity {
		$base_colors: $colors_opacity;
	} @else if $base == light {
		$base_colors: $colors_light;
	} @else if $base == dark {
		$base_colors: $colors_dark;
	} @else {
		$base_colors: $colors;
	}

	// Loop through each input paramater
	@each $hex, $rgba in $base_colors {
		@at-root #{$selector}.x#{$hex}#{$pseudo} {
			box-shadow: #{$shadow} #{$rgba};
		}
	}
}






@mixin responsive-768{
	@media only screen and (max-width: 768px) {
		@content;
	}
}









