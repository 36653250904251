.block-icon {

	fill: #7a7a7a;
	@include default-transition([all], 0.3s, ease);

	&.block-style1 {
    width: 100px;
    height: 100px;
//		width: 40px;
//		padding: 15px;
		background: $color_global_1;
		border-radius: 500px;

		path {
			fill: rgba(0, 0, 0, 0.15) !important;
		}

		// Generate color variations for icons
		@include default-color(#{&}, [background]);
	}

	&.block-style2 {
		font-family: 'Roboto';
		text-align: center;
		color: #2a2a2a;
		font-weight: 400;
		width: 28px;
		height: 28px;
		line-height: 28px;
		background: #fff;
		border: 1px solid #eee;

		&.xff6f91 {
			color: #fff;
			background: $color_ff6f91;
			border: 1px solid $color_ff6f91;
		}
	}
}