.page-home {

	// Minting on
	// &.mint-on {

	// 	// Section - Mint
	// 	#header-mint {
	// 		display: block;
	// 	}

	// 	// Section - Header
	// 	.container.home-header {

	// 		&.home-header-v2 {

	//       .block-footer {
	// 				display: none;
  //   	  }
	//     }
	// 	}
	// }

	// Home global styles
	#main {

		.container-row {

			> .block-header {
				padding-bottom: 52px;
			}
		}
	}

	// Section - Header
  .container.home-header {
    text-align: center;
    background: url("../../../assets/img/bg-header.png") no-repeat center center;
    background-size: cover;
    padding-top: 150px;
		padding-bottom: 5px;

    .block-body {
      margin-bottom: 60px;

      .block-image {
				text-align: center;

        img {
          margin: 0 auto;
        }
      }
    }

    &.home-header-v2 {
      padding-top: 130px;

      .block-footer {
        margin-bottom: 70px;
      }
    }

		.header-mint-notice {
			display: inline-block;
			color: #fff;
			font-size: 15px;
			background: #f94144;
			/* background: #F3202D; */
			/* background: #fe6c58; */
			padding: 8px 20px;
//			margin-bottom: 30px;
			-webkit-border-radius: 500px;
			-moz-border-radius: 500px;
			-ms-border-radius: 500px;
			-o-border-radius: 500px;
			border-radius: 500px;


			p {
				margin: 0;
			}

			.header-mint-icon {
		    margin-right: 10px;
			}
			.header-mint-text {

			}
		}
  }

	// Section - Featured
  .container.home-featured {
		padding-top: 50px;
		padding-bottom: 60px;

		&.container-row {
			max-width: 1100px;
			margin: 0 auto;
		}

		.block-image img {
			height: 100px;
			margin: 0 auto;
			opacity: 0.15;
		}
  }

	// Section - Why
  .container.home-why {
		padding-top: 70px;
		padding-bottom: 42px;

    .block-card {

      &.block-style1 {
        padding-top: 0;
        padding-bottom: 0;

        svg {
          width: 40px;
        }
      }
    }
  }

	// Section - Gallery
  .container.home-gallery {
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 70px;
    padding-bottom: 63px;
  }

	// Section - Roadmap
  .container.home-roadmap {
    background: url("../../../assets/img/bg-roadmap-v3.png") no-repeat center center;
    background-size: auto;
		padding-top: 70px;
		padding-bottom: 57px;

		.block-image {

			img {
				margin: 0 auto;
			}
		}
  }

	// Section - Rarity
  .container.home-rarity {
    background: url("../../../assets/img/bg-rarity.png") no-repeat center center;
    background-size: contain;
		padding-top: 70px;
		padding-bottom: 32px;

    .block-card {

      &.block-style2 {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

  }

	// Section - Utility
  .container.home-utility {
		padding-top: 70px;
		padding-bottom: 65px;

    .block-card {

      &.block-style1 {
        padding-top: 0;
        padding-bottom: 0;

        svg {
          width: 40px;
        }
      }
    }
  }

  // Section - Team
  .container.home-team {
		padding-top: 70px;
		padding-bottom: 35px;

    .block-card {

      &.block-style2 {
        padding: 0;

        .block-title {
          font-family: spaceboy;
          font-size: 24px;
        }
      }
    }
  }

	// Section - Faq
  .container.home-faq {
    background: url("../../../assets/img/bg-faq.png") no-repeat bottom center;
    background-size: cover;
		padding-top: 70px;
		padding-bottom: 140px;

    .block-body {
      max-width: 750px;
      margin: 0 auto;
    }
  }
}

// Desktops, large screens
@media only screen and (max-width: 1024px) {

}

// Small screens, laptops
@media only screen and (max-width: 800px) {

	.page-home {

		// Section - Featured
		.container.home-featured {

			.block-image img {
				height: 80px;
			}
		}
	}
}

// Small screens, laptops
@media only screen and (max-width: 480px) {

	.page-home {

		// Section - Header
		.container.home-header {

			&.home-header-v2 {
				padding-top: 80px;
			}
		}
	}
}
