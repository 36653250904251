body {

	// Cookie box Overlay
	#cky-modal-backdrop {
	    z-index: 9997;
		background: rgba(0,0,0,0.5);
	}

	// Cookie bar - Consent step 1
	#cky-consent {
	    z-index: 9998 !important;
		top: 100px !important;
		bottom: auto !important;
		left: 0 !important;
		right: 0 !important;
		margin: auto !important;
		border-radius: 4px !important;
		max-width: 460px !important;
		padding: 30px !important;

		// Box style
		&.cky-box {

		}

		.cky-consent-title {
			font-family: 'roboto';
			color: #2a2a2a;
			font-size: 16px;
			font-weight: 500;
			line-height: 1;
			margin-bottom: 15px;
		}

		.cky-button-wrapper {
			text-align: center;

			.cky-btn-settings {
				padding: 0;
				border: none;
				margin-right: 30px;
			}

			.cky-btn-accept {
				height: 35px !important;
				line-height: 35px !important;
				color: #fff !important;
				font-size: 13px !important;
				background: $color_ff6f91 !important;
				padding: 0 26px !important;
				border: none !important;
				border-radius: 4px !important;

				&:hover {
					background: darken( $color_ff6f91, 5% ) !important;
				}
			}
		}
	}

	// Show overlay if cookie modal is showing
//	#cky-consent[style*="block"] + #cky-modal-backdrop {
	#cky-consent + #cky-modal-backdrop {
		opacity: 1;
		display: block;
	}

	// Cookie bar - Consent step 2
	#cky-settings-popup {
		z-index: 9999 !important;
		align-items: start !important;
		justify-content: flex-start !important;

		.cky-modal-dialog {
			min-width: 310px !important;
			margin: 100px auto !important;
			align-items: start !important;
			justify-content: flex-start !important;
		}

		#cky-tab-menu {

			> button {
				overflow: hidden !important;
				display: block !important;
				font-size: 12px !important;
				font-weight: 400 !important;
				width: 150px !important;
				height: 35px !important;
				padding: 0 !important;
				border-radius: 4px !important;
			}

			#upify-cky-btn-accept {
				color: #fff !important;
				line-height: 35px !important;
				margin: 20px auto 10px !important;
				border: none !important;
				background: #ff6f91 !important;

				&:hover {
					background: darken( $color_ff6f91, 5% ) !important;
				}
			}

			#cky-btn-custom-accept {
				color: #7a7a7a !important;
				line-height: 33px !important;
				margin: 0 auto 20px !important;
				border: 1px solid #ddd !important;
				background: #fff !important;
			}
		}
	}
}

// iPads, Tablets
@media only screen and (max-width: 480px) {

	body {

		// Cookie bar - Consent step 1
		#cky-consent {
			top: 50px !important;
			max-width: 400px !important;
			padding: 30px 20px !important;
		}

		// Cookie bar - Consent step 2
		#cky-settings-popup {

			.cky-modal-dialog {
				margin: 50px auto !important;
			}

			#cky-tab-menu {

				> button {
					width: 80px !important;
				}

				.cky-tab-item {
					padding-left: 20px;
					padding-right: 20px;
				}
			}
		}
	}
}

// Mobile devices
@media only screen and (max-width: 360px) {

	body {

		// Cookie bar - Consent step 1
		#cky-consent {
			max-width: 310px !important;
			padding: 30px 15px !important;
		}
	}
}

