body {
	background: $color_bg_1;
}
#body-core {
	overflow: hidden;
}

/* Override UI Kit globals */
.uk-scope {

	.uk-navbar-nav > li a {
		font-family: "Poppins", Helvetica, Arial;
		text-transform: none;
	}
}

/* Base styling - Fonts */
body,
button,
input,
select,
textarea {
	color: $color_text_1;
	font-family: 'Poppins', Helvetica, Arial;
	/* font-family: 'Montserrat', Helvetica, Arial; */
	font-size: 15px;
	line-height: 1.8;
	word-wrap: break-word;
}
header {
	font-family: 'Poppins', Helvetica, Arial;
	/* font-family: 'Montserrat', Helvetica, Arial; */
}
h1,h2,h3,h4,h5,h6 {
	color: $color_heading_1;
	clear: both;
	margin: 0 0 20px;
	font-family: 'Nanum Pen Script', cursive, Helvetica, Arial;
	line-height: 1.4;
	@include default-transition([all], 0.3s, ease);
}

/* Base styling - Links */
a {
	color: $color_global_1;
	cursor: pointer;
	text-decoration: none;
	@include default-transition([all], 0.3s, ease);
	outline: none;

	&:hover {
		color: inherit;
	}

	&,
	&:hover,
	&:visited {
		text-decoration: none;
	}

	// Inverse link colors (dark to light)
	&.a-inverse {
		color: inherit;

		&:hover {
			color: $color_global_1;
		}
	}
}

p {
	margin-top: 0;
}

/* Base styling - Buttons */
button:focus {
	outline: none;
}

.disable-container {
	position: relative;
}
.disable-content {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

a[disabled],
button[disabled],
.disabled {
	opacity: 0.5 !important;
	cursor: wait !important;
}
.disabled > a {
	cursor: wait !important;
}
a[disabled] {
	color: inherit;
}
a[inactive],
button[inactive],
.inactive {
	opacity: 0.5 !important;
	cursor: default !important;
	pointer-events: none !important;
}
a[inactive] {
	color: inherit;
}

.input-title {
	margin-bottom: 15px;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

/* Misc */
.hidden-display {
	display: none;
}
.hidden-visible {
	opacity: 0;
	visibility: hidden;
}
.hidden-important {
	display: none !important;
}

.overflow-hide {
	overflow: hidden !important;
}
.overflow-show {
	overflow: visible !important;
}
.margin-0 {
	margin: 0;
}
.margin-right-0 {
	margin-right: 0;
}
.margin-bottom-0 {
	margin-bottom: 0;
}
.margin-top-40 {
	margin-top: 40px;
}