// Size for background images used in header area
$header_bg_width: 1100px;
$header_bg_height: 700px;

$step_loop: 5;
$width-max: 1200px;


$color_text_1: #9a9a9a;
$color_text_2: #5a5a5a;
$color_text_3: #999;


$color_global_1: #2580db;
$color_global_opensea: #2081E2;

$color_bg_1: #0d121e;
$color_bg_blue: #eff7f9;
$color_bg_grey: #f7f7f7;

$color_border_global_1: #eee;
$color_border_global_2: #f2f2f2;

$color_border_button_1: #ddd;
$color_border_button_2: #eee;

$color_heading_1: #2a2a2a;






@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@function force-hex($color) {
    @if type-of($color) == 'color' {
        $hex: str-slice(ie-hex-str($color), 4);
        @return to-lower-case( unquote("\##{$hex}") );
    }
    @return $color;
}


$color_opacity: 0.15;
$color_light: 10%;
$color_dark: 10%;

// Theme colors
$color_ffffff: rgba( #ffffff, 1);
$color_ff6f91: rgba( #ff6f91, 1);

// Character colors
$color_c9fe58: rgba( #c9fe58, 1);
$color_fec258: rgba( #fec258, 1);
$color_f5fe58: rgba( #f5fe58, 1);
$color_5887fe: rgba( #5887fe, 1);
$color_fe6c58: rgba( #fe6c58, 1);
$color_00d5fe: rgba( #00d5fe, 1);
$color_fd80bb: rgba( #fd80bb, 1);
$color_fcec5e: rgba( #fcec5e, 1);
$color_8c5afd: rgba( #8c5afd, 1);

//$color_c9fe58_opacity: #{force-hex(rgba($color_c9fe58, 0.5))};
$color_ffffff_opacity: rgba($color_ffffff, $color_opacity);
$color_ff6f91_opacity: rgba($color_ff6f91, $color_opacity);
$color_c9fe58_opacity: rgba($color_c9fe58, $color_opacity);
$color_fec258_opacity: rgba($color_fec258, $color_opacity);
$color_f5fe58_opacity: rgba($color_f5fe58, $color_opacity);
$color_5887fe_opacity: rgba($color_5887fe, $color_opacity);
$color_fe6c58_opacity: rgba($color_fe6c58, $color_opacity);
$color_00d5fe_opacity: rgba($color_00d5fe, $color_opacity);
$color_fd80bb_opacity: rgba($color_fd80bb, $color_opacity);
$color_fcec5e_opacity: rgba($color_fcec5e, $color_opacity);
$color_8c5afd_opacity: rgba($color_8c5afd, $color_opacity);

$color_ffffff_light: rgba($color_ffffff, $color_light);
$color_ff6f91_light: rgba($color_ff6f91, $color_light);
$color_c9fe58_light: rgba($color_c9fe58, $color_light);
$color_fec258_light: rgba($color_fec258, $color_light);
$color_f5fe58_light: rgba($color_f5fe58, $color_light);
$color_5887fe_light: rgba($color_5887fe, $color_light);
$color_fe6c58_light: rgba($color_fe6c58, $color_light);
$color_00d5fe_light: rgba($color_00d5fe, $color_light);
$color_fd80bb_light: rgba($color_fd80bb, $color_light);
$color_fcec5e_light: rgba($color_fcec5e, $color_light);
$color_8c5afd_light: rgba($color_8c5afd, $color_light);

$color_ffffff_dark: rgba($color_ffffff, $color_dark);
$color_ff6f91_dark: rgba($color_ff6f91, $color_dark);
$color_c9fe58_dark: rgba($color_c9fe58, $color_dark);
$color_fec258_dark: rgba($color_fec258, $color_dark);
$color_f5fe58_dark: rgba($color_f5fe58, $color_dark);
$color_5887fe_dark: rgba($color_5887fe, $color_dark);
$color_fe6c58_dark: rgba($color_fe6c58, $color_dark);
$color_00d5fe_dark: rgba($color_00d5fe, $color_dark);
$color_fd80bb_dark: rgba($color_fd80bb, $color_dark);
$color_fcec5e_dark: rgba($color_fcec5e, $color_dark);
$color_8c5afd_dark: rgba($color_8c5afd, $color_dark);

$colors: (
	"ffffff" : $color_ffffff,
	"ff6f91" : $color_ff6f91,
	"c9fe58" : $color_c9fe58,
	"fec258" : $color_fec258,
	"f5fe58" : $color_f5fe58,
	"5887fe" : $color_5887fe,
	"fe6c58" : $color_fe6c58,
	"00d5fe" : $color_00d5fe,
	"fd80bb" : $color_fd80bb,
	"fcec5e" : $color_fcec5e,
	"8c5afd" : $color_8c5afd,
);

$colors_opacity: (
	"ffffff" : $color_ffffff_opacity,
	"ff6f91" : $color_ff6f91_opacity,
	"c9fe58" : $color_c9fe58_opacity,
	"fec258" : $color_fec258_opacity,
	"f5fe58" : $color_f5fe58_opacity,
	"5887fe" : $color_5887fe_opacity,
	"fe6c58" : $color_fe6c58_opacity,
	"00d5fe" : $color_00d5fe_opacity,
	"fd80bb" : $color_fd80bb_opacity,
	"fcec5e" : $color_fcec5e_opacity,
	"8c5afd" : $color_8c5afd_opacity,
);

$colors_light: (
	"ffffff" : $color_ffffff_light,
	"ff6f91" : $color_ff6f91_light,
	"c9fe58" : $color_c9fe58_light,
	"fec258" : $color_fec258_light,
	"f5fe58" : $color_f5fe58_light,
	"5887fe" : $color_5887fe_light,
	"fe6c58" : $color_fe6c58_light,
	"00d5fe" : $color_00d5fe_light,
	"fd80bb" : $color_fd80bb_light,
	"fcec5e" : $color_fcec5e_light,
	"8c5afd" : $color_8c5afd_light,
);

$colors_dark: (
	"ffffff" : $color_ffffff_dark,
	"ff6f91" : $color_ff6f91_dark,
	"c9fe58" : $color_c9fe58_dark,
	"fec258" : $color_fec258_dark,
	"f5fe58" : $color_f5fe58_dark,
	"5887fe" : $color_5887fe_dark,
	"fe6c58" : $color_fe6c58_dark,
	"00d5fe" : $color_00d5fe_dark,
	"fd80bb" : $color_fd80bb_dark,
	"fcec5e" : $color_fcec5e_dark,
	"8c5afd" : $color_8c5afd_dark,
);


