.block-modal {
	text-align: center;

	.block-box{
    max-width: 95%;
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
    width: 430px;
		background-color: #fff;
    padding: 30px 20px;
		border: none;
    box-sizing: border-box;
		border-radius: 5px;
		box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
//			box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
		box-sizing: border-box;
	}

	.modal-title {

	}
	.modal-text {
    font-family: 'Poppins';
    font-size: 15px;
		line-height: 1.7;
	}
	.modal-info {

	}

	.modal-divider {
    margin-bottom: 15px;
    padding-bottom: 15px;
	}

	&.modal-complete {

		.block-box {

			.modal-info {
				font-size: 14px;
				font-weight: 300;
			}
		}
	}

	&.modal-error {

		&.error-connect {

			.block-box {

				.modal-divider,
				.modal-info {
					display: none;
				}
				.modal-info {
					margin-bottom: 5px;
				}
			}
		}
	}
}