// Breakpoints from https://www.freecodecamp.org/news/css-media-queries-breakpoints-media-types-standard-resolutions-and-more/
.button.header-cta {
	padding: 0;
	width: 250px;
	height: 60px;
	line-height: 48px;
	font-weight: 500;
	text-transform: uppercase;

	a {
		display: block;
		padding: 0 !important;
	}
}

	.container.page-header {

		.page-header-col1 {

			.block-header .title-info {
				margin-bottom: 10px;
			}

			.block-body .block-text {
				max-width: 580px;
				margin-bottom: 42px;
			}
		}

		.page-header-col2 {

			// This will only be displayed on features pages
			@at-root .page-features #{&} .block-image.header-preview {
				width: 100%;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translate(0%, -50%);
			}

			// This will only be displayed on solutions pages
			@at-root .page-solutions #{&} .block-image.header-solution {
			}

			.block-image.header-bg {
			    position: relative;
			    width: $header_bg_width;

				img{
					width: $header_bg_width;
					height: $header_bg_height;
				}
			}
		}
	}

	.container.page-intro {
		background: #f7f7f7;
		padding-top: 80px;
		padding-bottom: 100px;
	}


	.container.page-intro-divider {

		&.page-intro-divider-bottom {
			padding-bottom: 100px;
		}
	}












.w1024-show,
.w800-show,
.w480-show,
.w480-show-inline,
.w360-show,
.w360-show-inline,
.w320-show,
.w320-show-inline {
	display: none !important;
}

// Between 800px and 1366px
@media screen and (min-width: 800px) and (max-width: 1366px) {

	.container.page-header {

		.page-header-col2 {

			.block-image.header-bg {

				img {
					height: 600px;
				}
			}
		}
	}
}

// Extra large screens, TV
@media only screen and (max-width: 1200px) {

}

// Desktops, large screens
@media only screen and (max-width: 1024px) {

}

// Small screens, laptops
@media only screen and (max-width: 800px) {

}

// iPads, Tablets
@media only screen and (max-width: 480px) {

}

// Mobile devices
@media only screen and (max-width: 320px) {

}



























// Extra large screens, TV
@media only screen and (max-width: 1200px) {

	.w1200-hide {
		display: none;
	}

	.w1200-show {
		display: block !important;
	}
}

// Desktops, large screens
@media only screen and (max-width: 1024px) {

	.w1024-hide {
		display: none;
	}

	.w1024-show {
		display: block !important;
	}
}

// Small screens, laptops
@media only screen and (max-width: 800px) {

	.w800-hide {
		display: none;
	}

	.w800-show {
		display: block !important;
	}

	.w800-margin-right-0 {
		margin-right: 0;
	}

	.button.header-cta {
		font-size: 14px;
		width: 160px;
		height: 45px;
		line-height: 43px;
	}

	.button {

		&.button-l,
		&.button-l a {
			font-size: 14px;
			padding: 8px 20px;
		}
	}

	.container.page-intro {
		background: #f7f7f7;
		padding-top: 50px;
		padding-bottom: 70px;
	}

	.container.page-intro-divider {

		&.page-intro-divider-bottom {
			padding-bottom: 70px;
		}
	}

	// Feature page
	.page-features,
	.page-solutions {

		// Header features
		.container.page-header {
			text-align: center;
			padding-bottom: 50px;

			.container-core {
				max-width: 700px;

				.page-header-col1 {
					padding-top: 50px;

					.block-text {
						margin-bottom: 37px;
						margin-left: auto;
						margin-right: auto;
					}
				}

				.page-header-col2 {
					padding-top: 30px;

					.header-bg {
						display: none;
					}

					.block-image {
						position: relative;
						top: 0;
						transform: none;
						max-width: 95%;
	//						max-width: 85%;
						margin: 0 auto;
						padding: 0;

						img {
							margin: 0 auto;
						}
					}
				}
			}
		}

		// Homepage - All feature rows
		.container.page-feature {
			padding-bottom: 70px;

			.container-core {
				row-gap: 40px;
				max-width: 700px;
				text-align: center;
			}
		}
	}

	// Solutions page
/*	.page-solutions {

		// Header features
		.container.page-header {
			text-align: center;
			padding-bottom: 50px;

			.container-core {
				max-width: 700px;

				.page-header-col1 {
					padding-top: 50px;

					.block-text {
						margin-bottom: 37px;
						margin-left: auto;
						margin-right: auto;
					}
				}

				.page-header-col2 {
					padding-top: 30px;

					.header-bg {
						display: none;
					}

					.block-image {
						padding: 0;
						max-width: 95%;

						img {
							margin: 0 auto;
						}
					}
				}
			}
		}

		// Homepage - All feature rows
		.container.page-feature {
			padding-bottom: 70px;

			.container-core {
				row-gap: 40px;
				max-width: 700px;
				text-align: center;
			}
		}
	}*/

	// Homepage specific customization
	.page-home {

		.container.home-header {

			.home-header-v2 {
		    padding-top: 70px;
			}
		}

		// Homepage - Why
		.container.home-why {
			margin-top: 20px;
			padding-bottom: 70px;

			.title-section {
				max-width: 550px;
				margin-left: auto;
				margin-right: auto;
			}
		}

		// Homepage - how it works
		.container.home-how {
			padding-top: 70px;
			padding-bottom: 70px;
			margin-bottom: 70px;

			.container-core{
				max-width: 700px;

				.home-how-col1 {
					padding-bottom: 40px;

					.block-header {
						text-align: center;
					}
				}

				.home-how-col2 {

					.block-image {
//						max-width: 85%;
						margin: 0 auto;
					}
				}
			}
		}

		// Homepage - Why row
		.container.home-why {

			.why-grid {
			}
		}
	}

	// Block - CTA
	.block-cta {

		&.block-style1 {
			padding-top: 70px;
			padding-bottom: 50px;

			.block-header {

				.title-main {
					font-size: 50px;
				}
			}
		}
	}
}

// iPads, Tablets
@media only screen and (max-width: 480px) {

	// UIKit customization
	.uk-navbar-nav > li > a,
	.uk-navbar-item, .uk-navbar-toggle {
		min-height: 60px;
	}

	.title-section {
	    font-size: 32px;
	}

//	.button.header-cta-trial {
//		display: none;
//	}
	.button.header-cta-demo {
		display: none;
	}


	// Feature page
	.page-features,
	.page-solutions {

		// Header features
		.container.page-header {

			.container-core {

				.page-header-col1 {
					padding-top: 40px;

					.block-text {
						max-width: 350px;
						margin-bottom: 25px;
					}
				}

				.page-header-col2 {
					padding-top: 15px;
				}
			}
		}
	}

	// Homepage specific customization
	.page-home {

		// Homepage - Why row
		.container.home-why {

			.why-grid {
				grid-template-columns: 1fr;
			}
		}
	}

	.w480-hide {
		display: none !important;
	}

	.w480-show {
		display: block !important;
	}
	.w480-show-inline {
		display: inline-block !important;
	}

	.title-header {
		font-size: 40px;
	}

	.title-subsection {
		font-size: 30px;
	}

	#footer {

		.container.footer-main {
			padding-top: 50px;
			padding-bottom: 50px;

			.container-core {
				row-gap: 50px;
				text-align: center;
				align-items: center;
			}
		}
	}

	// Block - CTA
	.block-cta {

		&.block-style1 {
			padding-top: 50px;
			padding-bottom: 30px;

			.block-header {

				.title-main {
					font-size: 42px;
					margin-bottom: 35px;
				}
			}
		}
	}

	.block-card {

		&.block-style2 {
			text-align: center;

			.block-card-core {
				text-align: center;
				align-items: center;
				align-content: center;
			}
		}
	}
}

// iPads, Tablets
@media only screen and (max-width: 360px) {

	.button {

		&.w360-button-center {
			display: block;
			margin-left: auto !important;
			margin-right: auto !important;
		}
	}

	.w360-hide {
		display: none;
	}

	.w360-show {
		display: block !important;
	}
	.w360-show-inline {
		display: inline-block !important;
	}

	.title-header {
		font-size: 36px;
	}
	.title-section {
	    font-size: 30px;
	}

	.container.page-header {

		.page-header-col1 {

			.block-header .title-info {
//				display: none;
			}
		}
	}

	// Feature page
	.page-features,
	.page-solutions {

		// Header features
		.container.page-header {

			.container-core {

				.page-header-col1 {
					padding-top: 40px;
				}
			}
		}
	}

	// Block - CTA
	.block-cta {

		&.block-style1 {
			padding-top: 50px;
			padding-bottom: 30px;

			.block-header {

				.title-main {
					font-size: 32px;
					margin-bottom: 32px;
				}
			}
		}
	}
}


// Small screens, laptops
//@media only screen and (max-width: 800px) {
//
//	.block-flex {
//
//		&.w800-block-flex-column {
//			flex-direction: column;
//		}
//		&.w800-block-flex-column-reverse {
//			flex-direction: column-reverse;
//		}
//		&.w800-block-flex-center {
//			align-items: center;
//		}
//	}
//
//	.block-grid {
//
//		&.w800-grid-col-1 {
//			grid-template-columns: 1fr;
//		}
//		&.w800-grid-col-2 {
//			grid-template-columns: 1fr 1fr;
//		}
//	}
//}

// iPads, Tablets
//@media only screen and (max-width: 480px) {
//
//	.w480-block-flex-column {
//		flex-direction: column;
//	}
//	.w480-block-flex-column-reverse {
//		flex-direction: column-reverse;
//	}
//
//	.block-grid {
//
//		&.w480-grid-col-1 {
//			grid-template-columns: 1fr;
//		}
//	}
//}





// Mobile devices
@media only screen and (max-width: 320px) {

	.w320-hide {
		display: none;
	}

	.w320-show {
		display: block;
	}
}

