.container {
	overflow: hidden;

	.container-core {
		@include default-width-max;

		// Attach to root
		@at-root .container-wide#{&} {
			@include default-width-wide;
		}
	}

	.container-col {
		position: relative;
		box-sizing: border-box;
	}
}