body.page-error {

	#content {
		margin: 0;
		min-height: auto;
		background: #fff;
	}

	.block-title {
		font-family: 'Roboto';
		font-size: 56px;
		font-weight: 900;
		line-height: 1.3;
		margin-bottom: 24px;
	}

	.block-text {
		font-size: 15px;
	}
}