.block-accordion {

	&.block-open li.uk-open {

		.title-primary:before {
			display: none;
		}
	}

	.block-accordion-title {
		cursor: pointer;
		margin: 0;
	}

	.block-accordion-content {

		p:last-child {
			margin-bottom: 0;
		}
	}

	// Style 1
	&.block-style1 {
		margin: 0;

		li {
			margin-top: 2px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.1);

			&:first-child {
				margin-top: 0;
				border-top: 1px solid rgba(255, 255, 255, 0.1);
			}

			&.uk-open {

				.title-primary {
					color: #ffda00;
				}
			}
		}

		.title-primary {
			cursor: pointer;
			color: #fff;
			// background: #f7f7f7;
			font-size: 36px;
			font-weight: 400;
			padding: 15px 30px;
		}

		.block-accordion-content {
			padding: 10px 30px 30px;
			margin: 0;
		}
	}

	// Style 2
	&.block-style2 {

		.block-accordion-container {
			background: #fff;
			border-radius: 10px;

			.block-accordion-title {
				font-size: 17px;
				line-height: 35px;
				padding: 20px 30px;

				&::before {
//					content: "\f067";
					color: $color_heading_1;
					font-size: 16px;
					font-weight: 300;
					text-align: center;
					background-color: $color_bg_grey;
					width: 35px;
					height: 35px;
					margin: 0;
					border-radius: 5px;
				}
			}

			.block-accordion-content {
				margin-top: 10px;
				padding: 0 30px 30px;
			}

			// Styling when accordion is open
			&.uk-open {

				.block-accordion-title {
					&::before {
						font-family: "Font Awesome 5 Pro";
						content: "\f068";
						color: #fff;
						background: #ff6f91;
					}
				}
			}
		}
	}
}