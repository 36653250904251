/* CSS Flex */
.block-grid {
	display: grid;

	// Grid layouts
	&.grid-col-1 {
		grid-template-columns: 1fr;
	}
	&.grid-col-2 {
		grid-template-columns: 1fr 1fr;
	}
	&.grid-col-2-1 {
		grid-template-columns: 2fr 1fr;
	}
	&.grid-col-3 {
		grid-template-columns: 1fr 1fr 1fr;
	}
	&.grid-col-4 {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
	&.grid-col-5 {
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	}
	&.grid-col-6 {
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	}

	// Add range of grid sizes
	@for $count from 1 through 20 {
		$size : $step_loop * $count;
		&.grid-gap-#{$size} {
    	    grid-gap: #{$size}px;
        }
	}
	@for $count from 1 through 20 {
		$size : $step_loop * $count;
		&.grid-row-gap-#{$size} {
    	    row-gap: #{$size}px;
        }
	}
	@for $count from 1 through 20 {
		$size : $step_loop * $count;
		&.grid-column-gap-#{$size} {
    	    column-gap: #{$size}px;
        }
	}
}

// Desktops, large screens
@media only screen and (max-width: 1024px) {

	.block-grid {

		&.w1024-grid-col-1 {
			grid-template-columns: 1fr;
		}
		&.w1024-grid-col-2 {
			grid-template-columns: 1fr 1fr;
		}
		&.w1024-grid-col-3 {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}
}

// Small screens, laptops
@media only screen and (max-width: 800px) {

	.block-grid {

		&.w800-grid-col-1 {
			grid-template-columns: 1fr;
		}
		&.w800-grid-col-2 {
			grid-template-columns: 1fr 1fr;
		}
		&.w800-grid-col-3 {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}
}

// iPads, Tablets
@media only screen and (max-width: 480px) {

	.block-grid {

		&.w480-grid-col-1 {
			grid-template-columns: 1fr;
		}
		&.w480-grid-col-2 {
			grid-template-columns: 1fr 1fr;
		}
	}
}
