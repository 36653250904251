// Use specific font family
.font-narum {
	font-family: 'Nanum Pen Script', cursive;
}
.font-spaceboy {
	font-family: spaceboy;
}

// General titles used sitewide (these will be indiviudally styled when used)
.title-primary,
.title-secondary {
	color: #2a2a2a;
}

// Section headings
.title-header {
	font-size: 56px;
	font-weight: 900;
	line-height: 1.3;
	margin-bottom: 24px;
}
.title-header-page {
	font-size: 36px;
	font-weight: 600;
	line-height: 1.3;
	margin-bottom: 14px;
}
.title-header-info {
	color: #7a7a7a;
	font-size: 15px;
	font-weight: 400;
}
.title-info {
	text-align: center;
	color: #ffda00;
	font-size: 36px;
	line-height: 1;
	margin: 0 0 13px;
}
.title-section {
	text-align: center;
	color: #fff;
	font-size: 64px;
//	font-weight: 600;
	margin-top: 0;
	margin-bottom: 25px;
}
.title-subsection {
	text-align: center;
	color: #fff;
	font-size: 36px;
	margin-bottom: 20px;
}
.title-text {
	text-align: center;
	max-width: 500px;
	margin: 0 auto;
}

// Info titles {
// .title-info {
// 	@include default-color(#{&}, [background], ' span:before');

// 	span {
// 		display: inline-block;
// 		position: relative;

// 		&:before {
// 			content: '';
// 			display: block;
// 			position: absolute;
// 			width: 8px;
// 			height: 8px;
// 			background: #ddd;
// 			border-radius: 500px;
// 		}
// 	}

// 	&.style1 span {
// 		margin-left: 15px;

// 		&:before {
// 			top: 0;
// 			bottom: 0;
// 			left: -15px;
// 			right: 0;
// 			margin: auto 0;
// 		}
// 	}

// 	&.style2 span {
// 		margin-top: 15px;

// 		&:before {
// 			top: -15px;
// 			bottom: 0;
// 			left: 0;
// 			right: 0;
// 			margin: 0 auto;
// 		}
// 	}

// 	&.style3 span {
// 		margin-left: 20px;

// 		&:before {
// 			top: 0;
// 			bottom: 0;
// 			left: -20px;
// 			right: 0;
// 			width: 12px;
// 			height: 3px;
// 			margin: auto 0;
// 			border-radius: 0;
// 		}
// 	}
// }

// Text highlights - Miust be customzied for each time it is used */
.text-highlight {
	position: relative;

	i {
	    display: inline-block;
	    position: absolute;
	    z-index: -1;
		width: 100%;
		height: 100%;

		@for $count from 1 through 8 {
			@at-root .style#{$count}#{&} {
//				background: url( '../../img/highlights/png/highlight-#{$count}-yellow.png');
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
			}
		}
	}
}