.block-packages {

	.block-package {
		width: 400px;
		border: 1px solid #eee;
		box-sizing: border-box;

		> .block-header,
		.block-accordion-content > .block-header {
			margin-bottom: 20px;
			padding-bottom: 18px;
			border-bottom: 1px solid #eee;

			.subscription-plan {
				color: #2a2a2a;
				font-size: 18px;
				font-weight: 500;
				text-transform: capitalize;
				margin: 0;
			}

			.subscription-type {
				font-size: 13px;
				font-weight: 400;
			}
		}

		> .block-body,
		.block-accordion-content > .block-body {
			padding-bottom: 25px;
			margin-bottom: 25px;
			border-bottom: 1px solid #eee;

			.subscription-price {
				color: #2a2a2a;
				text-align: center;
				line-height: 1;
				margin-bottom: 22px;

				.price-icon {
					font-family: arial,sans-serif;
					font-size: 30px;
					font-weight: 600;
				}

				.price-number {
					font-size: 50px;
					font-weight: 700;
				}

				.price-letter {
					font-size: 50px;
					font-weight: 700;
				}
			}

			.subscription-trial {
				text-align: center;
				font-size: 13px;
				margin-top: 10px;
			}
		}

		> .block-footer,
		.block-accordion-content > .block-footer {
			padding-left: 10px;
		}
	}
}

// Extra large screens, TV
@media only screen and (max-width: 1200px) {

	.block-packages {

		.block-package {
			width: 350px;
		}
	}
}

// iPads, Tablets
@media only screen and (max-width: 360px) {

	.block-packages {

		.block-package {
			width: 100%;
		}
	}
}